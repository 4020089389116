.utility {

    background-image: url('../assets/images/ellipse2.svg');
    background-size: 100% 1000px;
    background-repeat: no-repeat;
    display: flex;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;
}

.utility-border {
    width: 100%;
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 20px;
}

.utility-title {
    width: 100%;
    font-family: 'Orbitron-m';
    font-size: 34px;
    color: #E4E4E4;
    text-align: center;
}

.utility-lineb img {
    width: 650px;
}

.utility-content {
    display: flex;
    justify-content: center;
    color: #E4E4E4;
    margin-top: 40px;
    margin-left: 80px;
    font-family: 'Oxanium-r';
    font-size: 20px;
}

.row1 {
    width: 50%;
    text-align: center;
}

.row2 {
    width: 50%;
    text-align: center;
}

.utilities-imgs {
    display: flex;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.education,
.dailyuse {

    margin-top: 70px;
    margin-bottom: 70px;
}

.computing,
.dailyuse,
.education,
.finance {
    color: #E4E4E4;
    font-family: 'Oxanium-r';
    font-size: 20px;
    background-image: url('../assets/images/utilitiesbg.png');
    background-repeat: no-repeat;
    padding: 100px;
    background-size: 200px 200px;
    background-position: center;


}

@media (max-width:768px) {
    .utility-border {
        margin-left: 40px;
        margin-right: 40px;
    }

    .utility-title {
        font-size: 28px;
    }

.utility-content{
    text-align: center;
}
    .utility-lineb img {
        width: 300px;

    }

    .computing,
    .dailyuse,
    .education,
    .finance {

        padding: 40px;
        background-size: 150px 150px;
    }


}


@media (max-width:450px) {
    .utility-border {
        margin-left: 0px;
        margin-right: 0px;
    }
    .utility-content {
        margin-left: 20px;
        margin-right: 20px;
    }
    .computing,
    .dailyuse,
    .education,
    .finance {

        padding: 40px;
        background-size: 100px 100px;
    }
    
    .utility-lineb img {
        width: 250px;

    }

}