/* Add this CSS to style the DeleteAccountRequest component */

/* html,
body,
#root {
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* body {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3f6db3;
    
} */

.text {
    color: #a8b2d1;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: larger;
    text-align: center;
}

.heading {
    color: black;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 600;
    margin-top: 130px;
}

.card {
    background-color: #162227;
    /* Dark blue background */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Box shadow for a futuristic look */
    padding: 20px;
    margin: 20px auto;
    width: 500px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
    position: relative;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup {
    background: #162227;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25%;
    width: 536px;
    height: 370px;
    text-align: center;

}

.innerPopup {
    margin-top: 130px;
}

.message_text {
    color: #a8b2d1;
    font-size: 1.3rem;
    margin-right: 10px;
    margin-bottom: 20px;
}

.main_popup {
    display: flex;
    justify-content: center;
    align-items: center;
}


label {
    color: #a8b2d1;
    /* Light gray text color */
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: large;
    font-weight: 500;
}

input {
    margin-left: 15px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #6272a4;
    /* Border color */
    border-radius: 5px;
    background-color: #1f2a48;
    /* Input background color */
    color: #a8b2d1;
    /* Input text color */
    font-size: large;
}

button {
    padding: 10px;
    background-color: #61dafb;
    /* Button background color */
    color: #0a192f;
    /* Button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: large;

}

button:hover {
    transform: scale(1.07);
}