.landing{
    z-index: 100;
}
.landing-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 2;


}

.landing-title {
    font-family: 'Orbitron-m';
    color: #ffffff;
    font-size: 40px;

}

.landing-about {
    padding-top: 30px;
    font-family: 'Oxanium-r';
    color: #E4E4E4;
    font-size: 20px;

}

.visitava {
    margin-top: 50px;
    width: 200px;
    height: 50px;
    font-family: 'Orbitron-m';
    color: #000000;
    font-size: 24px;
    border-radius: 10px;
    border: none;
}
@media (max-width:768px) {
    .landing-about {
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
    }
}
@media (max-width:450px) {
    .landing-about {
        margin-left: 50px;
        margin-right: 50px;
        text-align: center;
        font-size: 20px;
    }

    .landing-title {
        font-size: 26px;
    }
}