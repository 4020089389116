


.bg-sidenav {
    background: rgba(255, 255, 255, 0.126);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(65, 53, 53, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.22);
    width: fit-content;
    padding: 2px;
}

.appi {
    padding: 5px;
}



.bg-sidenav img {
    height: 40px;
}


.landing-leftIdle {
    margin-left: 30px;
    position: fixed;
    margin-top: 250px;
    top: 0;
    z-index: 100;
    animation: fade-in 1s forwards;
    -webkit-animation: fade-in 1s forwards;
}
.landing-leftActive {
    margin-left: 30px;
    position: fixed;
    margin-top: 250px;
    top: 0;
    z-index: 100;
    animation: fade-out 0.5s forwards;
    -webkit-animation: fade-out 0.5s forwards;

}


/* animations for slide in and out */
/* .selected {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
  
  .dismiss {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  } */
  @keyframes fade-in {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 786px) {
   
.bg-sidenav img {
  height: 30px;
}
  }
  
  /* @keyframes slide-in {
    0% {
      -webkit-transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(100%);
    }
  }
  
  @-webkit-keyframes slide-in {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  @keyframes slide-out {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  
  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(0%);
    }
  } */

  @media (max-width:450px) {
    .landing-leftIdle {
      margin-left: 10px;
    }
    .landing-leftActive {
      margin-left: 10px;
    }
  }