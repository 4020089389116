*{
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Orbitron-m';
  src: url('./assets/fonts/Orbitron-Medium.ttf');
}
@font-face {
  font-family: 'Oxanium-r';
  src: url('./assets/fonts/Oxanium-Regular.ttf');
}