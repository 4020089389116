.ava {

    height: 500px;
    display: flex;
}

.ava-section {

    display: flex;
    justify-content: center;
    height: 350px;
}

.ava-left {
    width: 50%;
    color: #E4E4E4;
    padding-left: 50px;


}

.ava-right img {
    height: 350px;
}

.whyava {
    font-family: 'Orbitron-m';
    font-size: 30px;
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
}

.ava-content {
    font-family: 'Oxanium-r';
    font-size: 20px;
}

@media (max-width:768px) {


    .ava-left {
        width: 90%;
    padding-left: 30px;
    padding-right: 30px;


    }

    .ava-right {
        
        display: none;
    }


    .ava-section {
        display: block;
    }
    
    .ava-content{
        text-align: center;
    }

}

@media (max-width:450px) {


    .ava-left {
        width: 80%;
    }
    
    .whyava {
        font-size: 24px;
    }

    .ava-content {
        font-size: 18px;
    }
}