.footer{
    background-color: #090B22;
    display: flex;
    padding: 70px 50px 70px 50px;
}
.pathor-title{
   font-family: 'Orbitron-m';
   font-size: 24px;
   margin-bottom: 20px;
}
.footer-left{
    width: 50%;
}
.footer-right{
    width: 50%;
    display: flex;
    justify-content: space-evenly;
}

.links{
    display: flex;
    margin-top: 40px;

}
.links img{
    height: 30px;
    
    margin-right: 20px;
}
.footer p{
    font-size: 14px;
    font-family: 'Oxanium-r';
    padding-bottom: 16px;
}
.footer h6{
    font-size: 20px;
    font-family: 'Oxanium-r';
    padding-bottom: 30px;
}

@media (max-width: 768px) {
    .footer{
 
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    .footer-right{
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 15px;
    }
    .footer-left{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .footer-right{
        width: 100%;
        
        align-items: center;
        justify-content: center;
    }
    .links{
        display: flex;
        justify-content: center;

    }
}