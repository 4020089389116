.feature {
    background-image: url('../assets/images/ellipse1.svg');
    background-repeat: no-repeat;
    background-size: 100% 500px;
    display: flex;
    padding-top: 200px;
    height: 100vh;
}

.left-feature {
    display: flex;
    width: 50%;
}

.feature-image-1 img,
.feature-image-2 img {
    height: 350px;
}

.feature-image-1 {
    animation: move-up forwards 150ms linear;
}

.feature-image-2 {
    animation: move-down forwards 150ms linear;
}

.right-feature {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: left;

}

.feature-title {
    font-family: 'Orbitron-m';
    font-size: 34px;
    color: #E4E4E4;
    text-align: left;
}

.feature-border {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.feature-lineb img {
    width: 600px;
}

.feature-content {
    color: #E4E4E4;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Oxanium-r';
    font-size: 24px;
    text-align: left;
}

.feature-sub-content {
    color: #e4e4e4c4;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Oxanium-r';
    font-size: 20px;
    text-align: left;
}

.accordion {
    margin-left: 100px;
    margin-top: 100px;
}

.accordion-btn {
    background-color: inherit;
    border: none;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.acc-icons {
    color: #E4E4E4;
}

@keyframes move-up {
    from {
        margin: 250px 0px 50px 110px;
    }

    to {
        margin: 50px 0px 50px 110px;
    }
}

@keyframes move-down {
    from {
        margin: 50px 0px 50px 110px;
    }

    to {
        margin: 250px 0px 50px 110px;
    }
}

@keyframes move-up-s {
    from {
        margin: 250px 0px 50px 50px;
    }

    to {
        margin: 50px 0px 50px 50px;
    }
}

@keyframes move-down-m {
    from {
        margin: 50px 0px 50px 70px;
    }

    to {
        margin: 250px 0px 50px 70px;
    }
}

@keyframes move-up-m {
    from {
        margin: 250px 0px 50px 50px;
    }

    to {
        margin: 50px 0px 50px 50px;
    }
}

@keyframes move-down-s {
    from {
        margin: 50px 0px 50px 50px;
    }

    to {
        margin: 250px 0px 50px 50px;
    }
}

@media (max-width:768px) {
    .feature {
        display: block;
    }

    .left-feature {
        width: 100%;
    }

    .feature-image-1 img,
    .feature-image-2 img {
        height: 250px;
    }

    .right-feature {
        width: 100%;

    }

    .feature-lineb img {
        width: 300px;
    }

    .feature-title {
        font-size: 28px;
    }

    .feature-content {
        font-size: 24px;
    }

    .accordion {
        margin-left: 40px;
        margin-top: 40px;
    }

    .accordion-btn {
        width: 80%;
    }

    .feature-image-1 {
        animation: move-up-m forwards 150ms linear;
    }

    .feature-image-2 {
        animation: move-down-m forwards 150ms linear;
    }
}

@media (max-width:450px) {

    .feature-image-1 img,
    .feature-image-2 img {
        height: 150px;
    }

    .feature-image-1 {
        animation: move-up-s forwards 150ms linear;
    }

    .feature-image-2 {
        animation: move-down-s forwards 150ms linear;
    }

    .feature-title {
        font-size: 24px;
    }

    .feature-content {
        font-size: 18px;
    }

}