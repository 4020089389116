.navbar{
    height: 100px;
}
.logo img{
    width: 90px;
}
.navbar-colourchange{
    animation: fade-nav-in forwards 300ms linear;
    height: 70px;
}
.navbar-content{
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    margin-left: 50px;
    margin-right: 50px;
}
.items-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    list-style: none;
}
.logo{
    font-size: 26px;
    font-family: 'Orbitron-m';
    
}
.items-content{
    font-size: 22px;
    color: #D9D9D9;
    font-family: 'Oxanium-r';
}

@media (max-width:720px) {
    .items-list{
        display: none;
    }
}
.download{
    background-image: url('../assets/images/downloadbg.png');
    background-color: #0A0E25;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15px 30px 10px 30px;
    font-family: 'Orbitron-m';
    font-size: 20px;
}

@keyframes fade-nav-in {
    from{
        background-color: #0A0E25;
    }
    to{
        background-color: inherit;
    }
}